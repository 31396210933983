

.lesun-site-heading {
    margin-bottom: 30px;
    text-align: center
}

.lesun-site-heading>h4 {
    color: #de050c;

    margin-bottom: 5px;
}

h4, h5, p {
    margin: 0;
    padding: 0;
}

a {
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: inherit
}

a:focus {
    text-decoration: none;
    outline: medium none;
    color: inherit
}

a:hover {
    color: inherit;
    text-decoration: none;
    color: inherit
}

.section_50 {
    padding: 50px 0;
}

.section_b_70 {
    padding-bottom: 70px
}

.single-blog-page-item {
    border-bottom: 3px dotted #ddd;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.blog-title a:hover {
    color: #de050c
}

