h1 {
  text-align: center;
  margin-top: 30px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0px auto;
}
.card-container .card {
  flex: 1 1 0%;
  margin: 10px 10px;
  background-color: #de050c;
  color: #fff;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease-in-out;
}

.img-container {
  order:0;
  height:30%
}
.card-title{
  order:1;
  height:40%
}
.card-description {
  order:2;
  height:30%;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   line-clamp: 2;
   -webkit-box-orient: vertical;
}
.card-button{
  order:3;
  height:30%
}

.card-container .card .img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0px auto;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 768px) {
  .card-container .card {
    flex: 0 0 100%;
    margin: 10px 0px;
  }
}
.card-container:hover .card {
  transform: scale(0.9);
  filter: blur(5px) grayscale(1);
}
.card-container:hover .card:hover {
  transform: scale(1.1);
  filter: blur(0px) grayscale(0);
  z-index: 999;
}
.card-container:hover .card:hover .img-container {
  /*background-color: #fff;*/
  transform: scale(1.1);
  font-size: 50px;
}
.card-container:hover .card:nth-child(1):hover .img-container {
  color: red;
}
.card-container:hover .card:nth-child(2):hover .img-container {
  color: #d85043;
}
.card-container:hover .card:nth-child(3):hover .img-container {
  color: red;
}
