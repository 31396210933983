h2 {
    letter-spacing: 0;
    font-weight: 400;
    position: relative;
    padding: 0 0 10px 0;
    font-weight: 400;
    line-height: normal;
    color: #111;
    margin: 0
}

h2 {
    font-size: 22px;
}

*, ::after, ::before {
    box-sizing: border-box
}

button:focus {
    outline: 0
}

:focus {
    outline: 0
}

button {
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer
}

.titlepage {
    text-align: center;
    padding-bottom: 60px
}

.titlepage h2 {
    font-size: 40px;
    color: #3e3e3e;
    line-height: 35px;
    font-weight: 700;
    padding: 0;
    padding-bottom: 20px
}

.contact .titlepage span {
    font-size: 17px;
    line-height: 28px
}

.contact {
    padding-top: 90px
}

.main_form {
    padding: 50px 50px;
    border: #008df3 solid 1px;
    border-radius: 40px;
    margin: 0 80px
}

.contact .main_form .form_contril {
    border: #008df3 solid 1px;
    margin-bottom: 20px;
    width: 100%;
    height: 45px;
    background: #fff;
    color: #0b0503;
    font-size: 25px;
    border-top: 0;
    border-right: 0;
    border-left: 0
}

.contact .main_form .invalid_input {
    border: rgb(254, 45, 45) solid 1px;
    margin-bottom: 20px;
    width: 100%;
    height: 45px;
    background: #fff;
    color: #0b0503;
    font-size: 25px;
    border-top: 0;
    border-right: 0;
    border-left: 0
}

.contact .main_form .textarea {
    border: #008df3 solid 1px;
    margin-bottom: 20px;
    width: 100%;
    height: 60px;
    background: #fff;
    color: #000000;
    font-size: 25px;
    border-top: 0;
    border-right: 0;
    border-left: 0
}

.contact .main_form .send_btn {
    font-size: 17px;
    transition: ease-in all .5s;
    background-color: rgb(254, 45, 45);
    color: #fff;
    padding: 13px 0;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
    width: 100%;
    max-width: 190px
}

.contact .main_form .send_btn:hover {
    background-color: #008df3;
    transition: ease-in all .5s;
    color: #fff;
    border-radius: 26px
}

@media (min-width: 576px) and (max-width: 767px) {
    .main_form {
        margin: 0
    }
}

@media (max-width: 575px) {
    .titlepage h2 {
        font-size: 35px;
        line-height: 50px
    }

    .main_form {
        padding: 25px;
        margin: 0
    }
}

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;

}

h2 {
    margin-top: 0;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button, input, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, input {
    overflow: visible
}

button {
    text-transform: none
}

button {
    -webkit-appearance: button
}

button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    overflow: auto;
    resize: vertical
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

h2 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit
}

h2 {
    font-size: 2rem
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }

    .contact{

    }
}

@media (min-width: 300px) {
    .contact{

    }
}


@media (min-width: 650px) {

    .contact{

    }

}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
    .contact{

    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.col-md-12, .col-sm-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width: 576px) {
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
    background-image: none
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5)
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
}

@supports ((-webkit-transform-style:preserve-3d) or (transform-style:preserve-3d)) {
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
}

@media print {
    *, ::after, ::before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    h2 {
        orphans: 3;
        widows: 3
    }

    h2 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px !important
    }

    .container {
        min-width: 992px !important
    }
}
