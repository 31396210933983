

@import url(https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i);
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700&display=swap');



a:focus {
    text-decoration: none;
    outline: medium none;
    color: inherit
}

a:hover {
    color: inherit;
    text-decoration: none;
    color: inherit
}

:-moz-placeholder {
    color: #242424;
    text-transform: capitalize;
}

::-moz-placeholder {
    color: #242424;
    text-transform: capitalize;
}

:-ms-input-placeholder {
    color: #242424;
    text-transform: capitalize;
}

::-webkit-input-placeholder {
    color: #242424;
    text-transform: capitalize;
}

.-ms-select-placeholder {
    color: #242424;
    text-transform: capitalize;
}

::-moz-selection {
    color: #fff;
    background-color: rgb(254, 45, 45);
}

::selection {
    color: #fff;
    background-color: rgb(254, 45, 45);
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}


/*================================================
03 - RESPONSIVE MENU CSS
==================================================*/

.finves-responsive-menu {
    display: none;
    position: absolute;
    right: 0;
    top: 5px;
    width: 100%;
    z-index: 999;

}

@media only screen and (max-width: 600px) {

    #menuButton{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .finves-responsive-menu {
        display: flex;
        position: absolute;
        right: 0;
        top: 5px;
        width: 100%;
        z-index: 999;

    }

    .navigation_menu {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
    }

    .container {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .mainmenu ul {
        text-align: center;
    }

    #navigation_menu{
        display: flex;
        justify-content: center;
        align-content: center;
    }

    #RatesAndYieldsText{

    }

    #RatesAndYields{

    }

    #RatesAndYieldsContainer {
        display: flex;;
        flex-direction: column;
        height:5rem;
    }
}

#RatesAndYieldsText{
    order:1;
    width:24%;
    z-index:1000;
    background-color:rgb(222, 5, 12);
    color:white;
}

#RatesAndYields{
    color: #000080;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
}

#RatesAndYieldsContainer {
    display: flex;;
    flex-direction: row;
    height:1.5rem;
}

.js-marquee{
    width: 76%;
    /*transform: translateX(0px);*/
    animation: 50.6368s linear 2s infinite normal none running marqueeAnimation-4401300;
}


.slicknav_nav a {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #000
}

.slicknav_menu {
    background: transparent;
}

.slicknav_menutxt {
    display: none !important;
}

.slicknav_arrow {
    float: right;
    color: rgb(254, 45, 45)
}

.slicknav_nav a:hover {
    border-radius: 0;
}

.slicknav_nav .slicknav_row:hover {
    border-radius: 0
}

.slicknav_nav a:hover,
.slicknav_nav .slicknav_row:hover {
    background: rgb(254, 45, 45) none repeat scroll 0 0;
    color: #fff
}

.slicknav_nav li {
    border-bottom: 1px solid #ddd;
}

.slicknav_nav li:last-child {
    border-bottom: 0px solid #ddd
}

.slicknav_nav {
    background: #fff none repeat scroll 0 0;
    border-top: 1px solid rgb(254, 45, 45);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.19)
}

.slicknav_btn {
    background-color: transparent !important;
    line-height: 0;
    margin-right: 0px;
    margin-top: 5px;
}

.slicknav_menu .slicknav_icon {
    float: none !important;
    margin: 0
}

.slicknav_icon-bar {
    height: 3px !important;
    margin: 5px 0;
    width: 30px !important;
}

.slicknav_menu .slicknav_icon-bar {
    background-color: #111;
}

.site-logo img {
    width: 75%;
    margin: 24px 0;
}

.mainmenu ul {
    text-align: right;
}

.mainmenu ul li {
    display: inline-block;
    position: relative
}

.mainmenu ul li a {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 17px;
    color: #081528;
    display: inline-block;
    padding: 30px 10px;
}

.mainmenu ul li a:hover,
.mainmenu ul li.active > a {
    color: rgb(254, 45, 45)
}

.mainmenu ul li ul {
    position: absolute;
    top: 120%;
    width: 200px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    left: 0;
    z-index: 99;
    background: #fff none repeat scroll 0 0;
    text-align: left;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.mainmenu ul li ul li {
    display: block
}

.mainmenu ul li ul li a {
    display: block;
    padding: 12px 15px;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.mainmenu ul li ul li:last-child a {
    border-bottom: 0px
}

.mainmenu ul li:hover ul {
    top: 100%;
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"
}

.mainmenu ul li ul li a:hover {
    background: rgb(254, 45, 45) none repeat scroll 0 0;
    color: #fff;
}

.header-action {
    margin: 20px 0;
}

.header-action a {
    display: block;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 17px;
    padding: 10px 10px;
    background: rgb(254, 45, 45) none repeat scroll 0 0;
    color: #fff;
    border-radius: 5px;
}

.header-action a:hover {
    background: #FE9347 none repeat scroll 0 0;
}

#menuButton a:hover {
    background: #FE9347 none repeat scroll 0 0;
}

#menuButton  {
    fill:red;
    display:none
}

img {
    max-width: 100%;
    height: auto
}

a {
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #6b739c;
    font-family: 'Josefin Sans', sans-serif;
}

a:focus {
    text-decoration: none;
    outline: medium none;
    color: inherit
}

a:hover {
    color: inherit;
    text-decoration: none;
    color: inherit
}

:-moz-placeholder {
    color: #242424;
    text-transform: capitalize;
}

::-moz-placeholder {
    color: #242424;
    text-transform: capitalize;
}

:-ms-input-placeholder {
    color: #242424;
    text-transform: capitalize;
}

::-webkit-input-placeholder {
    color: #242424;
    text-transform: capitalize;
}

.-ms-select-placeholder {
    color: #242424;
    text-transform: capitalize;
}

::-moz-selection {
    color: #fff;
    background-color: rgb(254, 45, 45);
}

::selection {
    color: #fff;
    background-color: rgb(254, 45, 45);
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}


/*================================================
03 - RESPONSIVE MENU CSS
==================================================*/

.finves-responsive-menu {
    display: none;
    position: absolute;
    right: 0;
    top: 5px;
    width: 100%;
    z-index: 999;

}

@media only screen and (max-width: 650px) {
    .col-lg-7 {
        display: none;
    }
    #menuButton{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .slicknav_menu {
        display: block;
    }

    .col-lg-3 {
        display: flex;
        flex-direction: column;
    }

    .slicknav_nav  {
        display: block;
        animation: growDown 300000ms
    }
    .slicknav_hidden {
        display: block;
        animation: growDown 300000ms
    }

    .finves-responsive-menu {
        display: none;
        position: relative;
        right: 0;
        width: 100%;
        z-index: 999;
        animation: growDown 300000ms

    }

    .navigation_menu {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
    }

    .container {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .mainmenu ul {
        text-align: center;
    }

    #navigation_menu{
        display: flex;
        justify-content: center;
        align-content: center;
    }

    #RatesAndYieldsText{
        width: 100%;
    }

    #RatesAndYields{
        width: 100%;
    }

    #RatesAndYieldsContainer {
        display: flex;;
        flex-direction: column;
        height:5rem;
    }

}


.slicknav_nav a {
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #000
}

.slicknav_menu {
    background: transparent;
}

.slicknav_menutxt {
    display: none !important;
}

.slicknav_arrow {
    float: right;
    color: rgb(254, 45, 45)
}

.slicknav_nav a:hover {
    border-radius: 0;
}

.slicknav_nav .slicknav_row:hover {
    border-radius: 0
}

.slicknav_nav a:hover,
.slicknav_nav .slicknav_row:hover {
    background: rgb(254, 45, 45) none repeat scroll 0 0;
    color: #fff
}

.slicknav_nav li {
    border-bottom: 1px solid #ddd;
}

.slicknav_nav li:last-child {
    border-bottom: 0px solid #ddd
}

.slicknav_nav {
    background: #fff none repeat scroll 0 0;
    border-top: 1px solid rgb(254, 45, 45);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.19)
}

.slicknav_btn {
    background-color: transparent !important;
    line-height: 0;
    margin-right: 0px;
    margin-top: 5px;
}

.slicknav_menu .slicknav_icon {
    float: none !important;
    margin: 0
}

.slicknav_icon-bar {
    height: 3px !important;
    margin: 5px 0;
    width: 30px !important;
}

.slicknav_menu .slicknav_icon-bar {
    background-color: #111;
}

.site-logo img {
    width: 75%;
    margin: 24px 0;
}

.mainmenu ul {
    text-align: right;
}

.mainmenu ul li {
    display: inline-block;
    position: relative
}

.mainmenu ul li a {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 17px;
    color: #081528;
    display: inline-block;
    padding: 30px 10px;
}

.mainmenu ul li a:hover,
.mainmenu ul li.active > a {
    color: rgb(254, 45, 45)
}

.mainmenu ul li ul {
    position: absolute;
    top: 120%;
    width: 200px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    left: 0;
    z-index: 99;
    background: #fff none repeat scroll 0 0;
    text-align: left;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s
}

.mainmenu ul li ul li {
    display: block
}

.mainmenu ul li ul li a {
    display: block;
    padding: 12px 15px;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.mainmenu ul li ul li:last-child a {
    border-bottom: 0px
}

.mainmenu ul li:hover ul {
    top: 100%;
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"
}

.mainmenu ul li ul li a:hover {
    background: rgb(254, 45, 45) none repeat scroll 0 0;
    color: #fff;
}

.header-action {
    margin: 20px 0;
}

.header-action a {
    display: block;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 17px;
    padding: 10px 10px;
    background: rgb(254, 45, 45) none repeat scroll 0 0;
    color: #fff;
    border-radius: 5px;
}

.header-action a:hover {
    background: #FE9347 none repeat scroll 0 0;
}

#menuButton a:hover {
    background: #FE9347 none repeat scroll 0 0;
}

#menuButton  {
    fill:red
}





@keyframes marqueeAnimation-4401300 {
    100% {
        transform: translateX(-1992px);
    }
}

