
h2,
p {
    margin: 0;
    padding: 0;
}

a {
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: inherit
}

a:focus {
    text-decoration: none;
    outline: medium none;
    color: inherit
}

a:hover {
    color: inherit;
    text-decoration: none;
    color: inherit
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

.section_100 {
    padding: 100px 0;
}

.single-service-widget {
    margin-bottom: 30px;
}

.single-service-widget li {
    border: 1px solid #eee;
    margin-bottom: 5px
}

.single-service-widget li:last-child {
    margin-bottom: 0
}

.single-service-widget a {
    border-left: 5px solid #fff;
    color: #000;
    display: block;
    font-weight: 500;
    padding: 10px;

}

.single-service-widget a:hover,
.single-service-widget ul li.active > a {
    background: #de050c none repeat scroll 0 0;
    border-left: 5px solid #000;
    color: #fff;
}

.single-service-widget ul li a i {
    float: right;
    font-size: 20px;
    margin-top: 2px;
}

.single-service-right > h2 {
    color: #222;
    display: inline-block;
    font-family: "Fira Sans", sans-serif;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;

    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.single-service-right > p {
    margin: 0 0 10px
}

.single-service-widget:last-child {
    margin-bottom: 0
}



@media screen and (max-width: 782px) {
    html {
        --wp-admin--admin-bar--height: 46px
    }
}


section {
    display: block
}

a {
    background-color: transparent
}

a:active, a:hover {
    outline: 0
}

@media print {
    *, :after, :before {
        color: #000 !important;
        text-shadow: none !important;
        background: 0 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }

    a, a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " (" attr(href) ")"
    }

    h2, p {
        orphans: 3;
        widows: 3
    }

    h2 {
        page-break-after: avoid
    }
}

:after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

a {
    color: #337ab7;
    text-decoration: none
}

a:focus, a:hover {
    color: #23527c;
    text-decoration: underline
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

h2 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px
}

h2 {
    font-size: 30px
}

p {
    margin: 0 0 10px
}

ul {
    margin-top: 0;
    margin-bottom: 10px
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 768px) {
    .container {
        width: 750px
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px
    }
}

.row {
    margin-right: -15px;
    margin-left: -15px
}

.col-md-3, .col-md-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

@media (min-width: 992px) {
    .col-md-3, .col-md-9 {
        float: left
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-3 {
        width: 25%
    }
}

.container:after, .container:before, .row:after, .row:before {
    display: table;
    content: " "
}

.container:after, .row:after {
    clear: both
}


/*# sourceMappingURL=bootstrap.min.css.map */

@charset "UTF-8";
.is-style-outline > :where(.wp-block-button__link), :where(.wp-block-button__link).is-style-outline {
    border: 2px solid;
    padding: .667em 1.333em
}

:where(.wp-block-columns.has-background) {
    padding: 1.25em 2.375em
}

@supports (-webkit-touch-callout:inherit) {
}

@supports (position:sticky) {
}

@supports ((-webkit-mask-image:none) or (mask-image:none)) or (-webkit-mask-image:none) {
}

ul {
    box-sizing: border-box
}

.wp-block-navigation:where(:not([class*=has-text-decoration])) a, .wp-block-navigation:where(:not([class*=has-text-decoration])) a:active, .wp-block-navigation:where(:not([class*=has-text-decoration])) a:focus {
    text-decoration: none
}

.wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) {
    background-color: inherit;
    color: inherit;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: start;
    opacity: 0;
    transition: opacity .1s linear;
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
    left: -1px;
    top: 100%
}

.wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) > .wp-block-navigation-item > .wp-block-navigation-item__content {
    display: flex;
    flex-grow: 1
}

.wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) > .wp-block-navigation-item > .wp-block-navigation-item__content .wp-block-navigation__submenu-icon {
    margin-right: 0;
    margin-left: auto
}

.wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) .wp-block-navigation-item__content {
    margin: 0
}

@media (min-width: 782px) {
    .wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) .wp-block-navigation__submenu-container {
        left: 100%;
        top: -1px
    }

    .wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) .wp-block-navigation__submenu-container:before {
        content: "";
        position: absolute;
        right: 100%;
        height: 100%;
        display: block;
        width: .5em;
        background: transparent
    }

    .wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) .wp-block-navigation__submenu-icon {
        margin-right: .25em
    }

    .wp-block-navigation .has-child :where(.wp-block-navigation__submenu-container) .wp-block-navigation__submenu-icon svg {
        transform: rotate(-90deg)
    }
}

.wp-block-navigation .has-child:where(:not(.open-on-click)):hover > .wp-block-navigation__submenu-container {
    visibility: visible;
    overflow: visible;
    opacity: 1;
    width: auto;
    height: auto;
    min-width: 200px
}

.wp-block-navigation .has-child:where(:not(.open-on-click):not(.open-on-hover-click)):focus-within > .wp-block-navigation__submenu-container {
    visibility: visible;
    overflow: visible;
    opacity: 1;
    width: auto;
    height: auto;
    min-width: 200px
}

.wp-block-navigation:where(.has-background), .wp-block-navigation:where(.has-background) .wp-block-navigation .wp-block-page-list, .wp-block-navigation:where(.has-background) .wp-block-navigation__container {
    gap: inherit
}

.wp-block-navigation:where(.has-background) .wp-block-navigation-item__content, .wp-block-navigation :where(.wp-block-navigation__submenu-container) .wp-block-navigation-item__content {
    padding: .5em 1em
}

.has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 8.4em;
    line-height: .68;
    font-weight: 100;
    margin: .05em .1em 0 0;
    text-transform: uppercase;
    font-style: normal
}

:where(p.has-text-color:not(.has-link-color)) a {
    color: inherit
}

.wp-block-post-comments .comment-reply-title :where(small) {

    margin-left: .5em
}

.wp-block-post-comments-form[style*=font-weight] :where(.comment-reply-title) {
    font-weight: inherit
}

.wp-block-post-comments-form[style*=font-family] :where(.comment-reply-title) {
    font-family: inherit
}

.wp-block-post-comments-form[class*=-font-size] :where(.comment-reply-title), .wp-block-post-comments-form[style*=font-size] :where(.comment-reply-title) {
    font-size: inherit
}

.wp-block-post-comments-form[style*=line-height] :where(.comment-reply-title) {
    line-height: inherit
}

.wp-block-post-comments-form[style*=font-style] :where(.comment-reply-title) {
    font-style: inherit
}

.wp-block-post-comments-form[style*=letter-spacing] :where(.comment-reply-title) {
    letter-spacing: inherit
}

.wp-block-post-comments-form .comment-reply-title :where(small) {

    margin-left: .5em
}



