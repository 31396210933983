


*,
::after,
::before {
    box-sizing: border-box;
}

.container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.bg-contrast {
    padding: 100px 0;
    margin: 0 auto;
}

.service-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.card {
    padding: 30px;
    box-shadow: 0 0 10px rgba(48, 63, 100, 0.15);
    background-color: white;
    border-radius: 30px;
    transition: 0.15s ease-in;
    display: flex;
    flex-direction: column;
}

.card:hover{
    box-shadow: 0 0 10px rgb(222, 5, 12);
    transform: scale(1.02);
    font-size: 50px;
}

.card span {
    width: 4.375rem;
    height: 4.375rem;
    display: block;
    float: left;
    margin-right: 15px;
    border-radius: 50%;
    background: #b0aebe;
}

span.bg-color-1 {
    background-image: linear-gradient(90deg, #9ccdfe 0%, #94aafd 100%);
}
span.bg-color-2 {
    background-image: linear-gradient(90deg, #55edc0 0%, #4bdbdb 100%);
}
span.bg-color-3 {
    background-image: linear-gradient(90deg, #fda498 0%, #f58b9e 100%);
}
span.bg-color-4 {
    background-image: linear-gradient(90deg, #f2a7ca 0%, #c289f4 100%);
}
span.bg-color-5 {
    background-image: linear-gradient(90deg, #ffc187 0%, #fe93a7 100%);
}
span.bg-color-6 {
    background-image: linear-gradient(90deg, #9ccdf3 0%, #94aafd 100%);
}


@media (min-width: 1200px) {
    .container { max-width: 1140px; }
}
